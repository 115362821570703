import { Container } from '@mui/material';
import { styled } from '@mui/system';

const SlimMainContainer = styled(Container)(({ theme }) => ({
  marginTop: '32px',
  marginBottom: '24px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '0px',
  paddingRight: '0px',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export default SlimMainContainer;
