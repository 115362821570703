import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Link,
  useTheme,
} from "@mui/material";
import logo from "../../assets/mini_logo_grey.svg";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.footer.main, pb: 2 }}>
      <Container sx={{ px: 3, pt: 3 }}>
      <Grid container spacing={1} sx={{ml:0}} justifyContent="space-between">
          <Grid item xs={12} sm={2}>
            <Link href="/" underline="none">
              <Box
                component="img"
                alt="company logo"
                src={logo}
                sx={{
                  ml: "-14px",
                  mt: "-13px",
                  height: "100px",
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ px: 3 }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamily,
              }}
            >
              Copyright © {new Date().getFullYear()} Enhanced Impact Limited - All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;