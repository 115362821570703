import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../middleware/supabase';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const { data: sessionResponse, error } = await supabase.auth.getSession();

      if (!error && sessionResponse && sessionResponse.session) {
        setUser(sessionResponse.session.user);
      } else {
        console.error('Error fetching session:', error);
        setUser(null);
      }
      setIsLoading(false);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, sessionResponse) => {
      setUser(sessionResponse?.user || null); // Update user on auth state changes
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  const signIn = async (email, password) => {
    setIsLoading(true);
    try {
      const response = await supabase.auth.signInWithPassword({ email, password });
      const { data, error } = response; // First, capture the whole response
  
      if (error) {
        console.error('Sign in error:', error.message);
        return { data: null, error }; // Return error if login fails
      } else {
        setUser(data.user); // Set the user state here on successful login with data.user
        return { data: { user: data.user, session: data.session }, error: null }; // Return user and session within data
      }
    } catch (error) {
      console.error('Sign in error:', error.message);
      return { data: null, error }; // Handle unexpected errors
    } finally {
      setIsLoading(false);
    }
  };
  
  const signOut = async () => {
    setIsLoading(true); // Reflect the sign-out process
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      navigate('/');// Ensure user state is cleared on sign-out
    } catch (error) {
      console.error('Error signing out:', error.message);
    } finally {
      setIsLoading(false); // Reset loading state regardless of signOut outcome
    }
  };

  // Value provided through context to consumers
  const value = {
    user,
    isAuthenticated: !!user,
    isLoading,
    signIn,
    signOut,
  };

  // Conditionally render children or a loading indicator based on the isLoading state
  return (
    <AuthContext.Provider value={value}>
      {isLoading ? <CircularProgress /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);