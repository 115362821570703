import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes, } from "react-router-dom";
import ThemeCustomization from "./themes";

import { AuthProvider } from "./contexts/AuthProvider";
import { SnackbarProvider } from './contexts/SnackbarProvider';
import { LoadingProvider } from './contexts/LoadingContext';

import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";

import Home from './pages/Home';
import Login from './pages/Login';
import UserDashboard from './pages/UserDashboard';
import ContactForm from './pages/Contact';
import ProposalDetails from './pages/Proposal';

import ProtectedRoute from './components/routes/ProtectedRoute';

function App() {

  return (
    <ThemeCustomization>
      <LoadingProvider>
        <AuthProvider>
          <Box sx={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Navigation />
              <SnackbarProvider>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/contact" element={<ContactForm />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path="/dashboard" element={<UserDashboard />} />
                    <Route path="/proposal/:proposalId" element={<ProposalDetails />} />
                  </Route>
                </Routes>
              </SnackbarProvider>
            </Box>
            <Footer />
          </Box>
        </AuthProvider>
      </LoadingProvider>
    </ThemeCustomization>
  );
}

export default App;