import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../middleware/supabase';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import MainContainer from '../components/page-items/MainContainer';

export default function MainDashboard() {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  const fetchProposals = useCallback(async () => {
    setLoading(true);
    try {
      // Fetch the user's client ID
      let { data: userData, error: userError } = await supabase
        .from('profiles')
        .select('client_id')
        .eq('id', user.id);
        
      if (userError) throw userError;
      if (!userData || userData.length === 0) throw new Error("User not found");
      if (userData.length > 1) throw new Error("Multiple users found");

      const clientId = userData[0].client_id;

      // Fetch proposals for the user's client
      let { data: proposalsData, error: proposalsError } = await supabase
        .from('proposals')
        .select('*')
        .eq('client_id', clientId);

      if (proposalsError) throw proposalsError;

      setProposals(proposalsData);
    } catch (error) {
      console.error('Error fetching proposals:', error.message);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchProposals();
    }
  }, [fetchProposals, user]);

  const handleRowClick = (proposalId) => {
    navigate(`/proposal/${proposalId}`);
  };

  return (
    <MainContainer>
      <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
        <Typography variant="h2">Your documents</Typography>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        proposals.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell>Reference</TableCell>
                <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposals.map((proposal) => (
                  <TableRow
                    key={proposal.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    onClick={() => handleRowClick(proposal.id)}
                  >
                    <TableCell>{proposal.document_reference}</TableCell>
                    <TableCell component="th" scope="row">
                      {proposal.title}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No proposals available.</Typography>
        )
      )}
    </MainContainer>
  );
}