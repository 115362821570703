import { useRef, useState, createContext } from "react";
import { Alert, Container, Button, Card, CardContent, TextField, Typography, Box, Link, useTheme } from "@mui/material";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { supabase } from '../middleware/supabase';

import MainContainer from '../components/page-items/MainContainer';

import { Auth } from '@supabase/auth-ui-react'
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'

const AuthBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  background: '#fff',
  marginBottom: '24px',
  marginTop: '24px',
  borderRadius: '12px',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignSelf: 'center',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const InsideAuthBox = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  supabase.auth.onAuthStateChange(async (event, session) => {
    console.log("Auth state changed:", event);
    console.log("Session:", session);

    if (event === "SIGNED_IN" || event === "USER_UPDATED") {
      try {
        const { data, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", session.user.id);

        if (error) {
          throw error;
        }

        if (data.length === 0) {
          const { insertError } = await supabase
            .from("profiles")
            .insert([{ id: session.user.id, email: session.user.email }]);
          
          if (insertError) {
            throw insertError;
          }
        }

        navigate(from, { replace: true });
      } catch (err) {
        console.error("Error:", err.message);
      }
    }
  });

  return (
      <MainContainer>
        <AuthBox>
          <InsideAuthBox>
            <Auth
              supabaseClient={supabase}
              showLinks={false}
              //providers={['google']}
              providers={[]}
              appearance={{
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: theme.palette.primary.main,
                      brandAccent: theme.palette.primary.main,
                      brandButtonText: theme.palette.primary.contrastText,
                      defaultButtonBackground: 'white',
                      defaultButtonBackgroundHover: '#eaeaea',
                      defaultButtonBorder: 'lightgray',
                      defaultButtonText: 'gray',
                    },
                  },
                },
              }}
            />
          </InsideAuthBox>
        </AuthBox>
      </MainContainer>
  );
};

export default Login;