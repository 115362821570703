import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    Link,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useTheme,
    useMediaQuery,
    Stack,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CloseIcon from "@mui/icons-material/Close";
import ScrolledLogo from "../../assets/main_logo.svg";
import DefaultLogo from "../../assets/main_logo_gradient.svg";

function ElevationScroll(props) {
    const theme = useTheme();
    const { children, window, logoDefault, logoScrolled, isHomepage, isSmallScreen } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    const logo = isHomepage && !isSmallScreen ? (trigger ? logoScrolled : logoDefault) : logoScrolled;

    return React.cloneElement(children, {
        elevation: trigger ? 0 : 0,
        sx: {
            backgroundColor: trigger || !props.transparent ? "rgba(252, 252, 252, .8)" : "transparent",
            backdropFilter: trigger || !props.transparent ? "saturate(180%) blur(20px)" : "saturate(100%) blur(0px)",
            transition: "background-color 0.45s",
        },
        children: (
            <>
                {React.cloneElement(children.props.children, {
                    children: (
                        <>
                            <Link href="/" underline="none">
                                <Box
                                    component="img"
                                    alt="company logo"
                                    src={logo}
                                    sx={{
                                        height: '80px'
                                    }}
                                />
                            </Link>
                            {children.props.children.props.children}
                        </>
                    )
                })}
            </>
        ),
    });
}

const Navigation = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isAuthenticated, signOut } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    const isHomepage = location.pathname === "/";

    const handleLogout = (event) => {
        event.preventDefault();
        signOut(); // Logout logic
        navigate('/'); // Navigate to homepage after logout
    };

    const AuthButtons = () => {
        if (isAuthenticated) {
            return (
                <Stack direction="row" spacing={2}>
                    <Button href="/" onClick={handleLogout} sx={{ color: theme.palette.text.primary, border: `1px solid ${theme.palette.primary.main}`, backgroundColor: theme.palette.primary.bg, minWidth: 0, px: 2 }}>
                        Logout
                    </Button>
                </Stack >
            );
        }
        return (
            <Stack direction="row" spacing={2}>
                <Button href="/login" sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.text.primary, backgroundColor: theme.palette.primary.bg, minWidth: 0, px: 2 }}>
                    Login
                </Button>
            </Stack>
        );
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    const MenuButtons = () => {
        if (isAuthenticated) {
            return (
                <>
                    {isSmallScreen ? (
                        <Stack direction="column" spacing={2}>
                            <Button href="/dashboard" sx={{ color: theme.palette.text.primary, justifyContent: 'start' }}>Dashboard</Button>
                        </Stack>
                    ) : (
                        <Stack direction="row" spacing={2}>
                            <Button href="/dashboard" sx={{ color: theme.palette.text.primary }}>Dashboard</Button>
                        </Stack>
                    )}
                </>
            );
        }
    }

    const list = () => (
        <Box
            sx={{ width: "250px" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={toggleDrawer(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "16px 0",
                }}
            >
                <AuthButtons />
            </Box>
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "16px",
                }}
            >
                <MenuButtons />
            </Stack>
        </Box>
    );

    return (
        <ElevationScroll transparent {...props} logoDefault={DefaultLogo} logoScrolled={ScrolledLogo} isHomepage={isHomepage} isSmallScreen={isSmallScreen}>
            <AppBar position="sticky">
                <Toolbar sx={{
                    px: { xs: theme.spacing(2), md: theme.spacing(12) },
                }}>
                    <Box sx={{ flexGrow: 1 }} />
                    {isSmallScreen ? (
                        <>
                            <IconButton
                                onClick={toggleDrawer(true)}
                                sx={{ ml: 2, color: theme.palette.text.primary }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={drawerOpen}
                                onClose={toggleDrawer(false)}
                            >
                                {list()}
                            </Drawer>
                        </>
                    ) : (
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <MenuButtons />
                            <AuthButtons />
                        </Stack>
                    )}
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
};

export default Navigation;