import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../middleware/supabase';
import { Box, Fab, Typography, CircularProgress, Paper, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainContainer from '../components/page-items/MainContainer';

const ProposalDetails = () => {
  const { proposalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [proposal, setProposal] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const fetchProposal = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('proposals')
          .select('*')
          .eq('id', proposalId)
          .single();

        if (error) throw error;

        console.log('Fetched proposal data:', data);
        setProposal(data);
      } catch (error) {
        console.error('Error fetching proposal:', error.message);
      } finally {
        setLoading(false);
      }
    };

    if (proposalId) {
      fetchProposal();
    }
  }, [proposalId]);

  if (loading) {
    return (
      <MainContainer>
        <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
          <Typography variant="h2">Proposal Details</Typography>
        </Box>
        <CircularProgress />
      </MainContainer>
    );
  }

  if (!proposal) {
    return (
      <MainContainer>
        <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
          <Typography variant="h2">Proposal Details</Typography>
        </Box>
        <Typography>No proposal details available.</Typography>
      </MainContainer>
    );
  }

  const proposalData = proposal.sections.proposal;
  if (!proposalData) {
    return (
      <MainContainer>
        <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
          <Typography variant="h2">Proposal Details</Typography>
        </Box>
        <Typography>No valid proposal data available.</Typography>
      </MainContainer>
    );
  }

  const { title, preparedFor, date, documentReference, sections } = proposalData;

  if (!sections || !Array.isArray(sections)) {
    return (
      <MainContainer>
        <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
          <Typography variant="h2">Proposal Details</Typography>
        </Box>
        <Typography>No valid sections available in this proposal.</Typography>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 3, mb: 2 }}>
        <Fab size="small" sx={{ mb: 2, minWidth: '40px', minHeigh: '40px', boxShadow: 'unset' }} color="primary" onClick={handleButtonClick} aria-label="back to dashboard">
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h2" gutterBottom>{title}</Typography>
      </Box>
      <Paper sx={{ p: 2 }}>
        <Box sx={{display:'flex', flexDirection:'row', gap: 4}}>
        <Typography variant="subtitle1" gutterBottom>Prepared for: {preparedFor}</Typography>
        <Typography variant="subtitle1" gutterBottom>Date: {date}</Typography>
        <Typography variant="subtitle1" gutterBottom>Document Reference: {documentReference}</Typography>
        </Box>
        {sections.map((section, index) => (
          <Box key={index} sx={{ mt: 2 }}>
            <Typography variant="h3" sx={{mb: 2}}>{section.title}</Typography>
            {section.content && <Typography>{section.content}</Typography>}
            {section.subsections && section.subsections.map((sub, subIndex) => (
              <Box key={subIndex} sx={{ mt: 1 }}>
                <Typography variant="h4">{sub.title}</Typography>
                {sub.content && <Typography>{sub.content}</Typography>}
                {sub.features && (
                  <ul style={{ marginBlockStart: '8px' }}>
                    {sub.features.map((feature, featureIndex) => (
                      <li key={featureIndex}><Typography>{feature}</Typography></li>
                    ))}
                  </ul>
                )}
                {sub.dashboards && (
                  <ul style={{ marginBlockStart: '8px' }}>
                    {sub.dashboards.map((dashboard, dashboardIndex) => (
                      <li key={dashboardIndex}>
                        <Typography>{dashboard.role}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
                {sub.details && (
                  <ul style={{ marginBlockStart: '8px' }}>
                    {sub.details.map((detail, detailIndex) => (
                      <li key={detailIndex}><Typography>{detail}</Typography></li>
                    ))}
                  </ul>
                )}
              </Box>
            ))}
            {section.stack && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h4">Frontend</Typography>
                <Typography gutterBottom>{section.stack.frontend}</Typography>
                <Typography variant="h4">Backend</Typography>
                <Typography gutterBottom>{section.stack.backend}</Typography>
                {section.stack.integrations && (
                  <Box>
                    <Typography variant="h4">Integrations</Typography>
                    <ul style={{ marginBlockStart: '8px' }}>
                      {section.stack.integrations.map((integration, integrationIndex) => (
                        <li key={integrationIndex}><Typography>{integration}</Typography></li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            )}
            {section.phases && (
              <Box sx={{ mt: 2 }}>
                {section.phases.map((phase, phaseIndex) => (
                  <Box key={phaseIndex} sx={{ ml: 2, mt: 1 }}>
                    <Typography>Phase: {phase.phase}</Typography>
                    <Typography>Timeline: {phase.timeline}</Typography>
                    <Typography>Activities: {phase.activities}</Typography>
                  </Box>
                ))}
              </Box>
            )}
            {section.benefits && (
              <Box>
                <ul style={{ marginBlockStart: '8px' }}>
                  {section.benefits.map((benefit, benefitIndex) => (
                    <li key={benefitIndex}><Typography>{benefit}</Typography></li>
                  ))}
                </ul>
              </Box>
            )}
            {section.options && (
              <Box sx={{ mt: 2 }}>
                {section.options.map((option, optionIndex) => (
                  <Box key={optionIndex} sx={{ mt: 1 }}>
                    <Typography variant="h4">{optionIndex + 1}. {option.type}</Typography>
                    <Typography>{option.description}</Typography>
                    {option.suboptions && (
                      <Box sx={{ mt: 1 }}>
                        <ul style={{ marginBlockStart: '8px' }}>
                          {option.suboptions.map((suboption, suboptionIndex) => (
                            <li key={suboptionIndex}><Typography>{suboption.type}: {suboption.description}</Typography></li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}
      </Paper>
    </MainContainer>
  );
};

export default ProposalDetails;
