import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const SplitScrollSection = ({ title, subsections }) => {
  const theme = useTheme();
  const sectionRef = useRef(null);
  const [currentSubsection, setCurrentSubsection] = useState(0);

  const handleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const sectionHeight = sectionRef.current.getBoundingClientRect().height;
      const windowHeight = window.innerHeight;

      // Calculate progress with consideration of the entire section height
      const progress = Math.min(
        Math.max(0, windowHeight - sectionTop),
        sectionHeight + windowHeight // Ensure the bottom of the section is also considered
      ) / (sectionHeight + windowHeight);

      const index = Math.floor(progress * subsections.length);
      setCurrentSubsection(index);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const containerStyle = {
    display: 'flex',
    width: '100%',
    height: `${subsections.length * 100 + 200}vh`, // Set height based on number of subsections and additional length for the last section
    position: 'relative',
  };

  const leftSideStyle = {
    flex: '0 0 30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: theme.palette.primary.gradientVertical,
    padding: '20px',
    borderRight: '1px solid #ddd',
    position: 'sticky',
    top: 0,
    height: '100vh',
  };

  const rightSideStyle = {
    flex: '1 0 70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '100vh',
    position: 'sticky',
    top: 0,
  };

  const subsectionStyle = {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    width: '100%',
    textAlign: 'start',
    position: 'absolute',
    padding: '20px',
    top: 0,
    left: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  const activeSubsectionStyle = {
    ...subsectionStyle,
    opacity: 1,
  };

  return (
    <div style={{ marginTop: '0', height: `${subsections.length * 100 + 200}vh` }} ref={sectionRef}>
      <div style={containerStyle}>
        <div style={leftSideStyle}>
          <h2>{title}</h2>
        </div>
        <div style={rightSideStyle}>
          {subsections.map((subsection, index) => (
            <div
              key={index}
              style={index === currentSubsection ? activeSubsectionStyle : subsectionStyle}
            >
              <h3>{subsection.subtitle}</h3>
              <p>{subsection.body}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplitScrollSection;