import { Container } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Container)`
margin-top: 32px;
margin-bottom: 24px;
margin-left: auto;
margin-right: auto;
padding-left:0px;
padding-right:0px;
justify-content:center;
align-content:center;
display:flex;
flex-direction:column;

${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left:16px;
    padding-right:16px;
  }
`;

export default MainContainer;
