import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoadingState = (state) => {
    console.log('Setting loading state to:', state);
    setIsLoading(state);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading: setLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);