import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { supabase } from '../middleware/supabase';
import MainContainer from '../components/page-items/MainContainer';

function ContactForm() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [turnstileToken, setTurnstileToken] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!turnstileToken) {
      alert('Please complete the security check.');
      return;
    }

    const { data, error } = await supabase
      .from('contacts')
      .insert([
        { name: formData.name, email: formData.email, message: formData.message }
      ]);

    if (error) {
      console.error('Error submitting form:', error.message);
      alert('Failed to send message.');
    } else {
      alert('Thank you for your message!');
      setFormData({ name: '', email: '', message: '' }); // Reset form
      setTurnstileToken(''); // Reset token
    }
  };

  return (
    <MainContainer>
      <Typography variant="h3" gutterBottom>Contact Us</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          label="Name"
          name="name"
          fullWidth
          variant="outlined"
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          sx={{overflow: 'unset'}}
        />
        <TextField
          required
          label="Email"
          name="email"
          fullWidth
          type="email"
          variant="outlined"
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          sx={{overflow: 'unset'}}
        />
                <TextField
          required
          label="Phone"
          name="phone"
          fullWidth
          type="phone"
          variant="outlined"
          margin="normal"
          value={formData.phone}
          onChange={handleChange}
          sx={{overflow: 'unset'}}
        />
        <TextField
          required
          label="Message"
          name="message"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          value={formData.message}
          onChange={handleChange}
          sx={{overflow: 'unset'}}
        />
        <div
          className="cf-turnstile"
          data-sitekey="your_turnstile_site_key"
          data-callback={token => setTurnstileToken(token)}
        ></div>
        <Button type="submit" variant="contained" color="primary">
          Send
        </Button>
      </form>
    </MainContainer>
  );
}

export default ContactForm;