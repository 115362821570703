// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (theme) => ({
  fontFamily: `'DM Sans', sans-serif`,
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: {
    //marginBlockStart: "0.67em",
    //marginBlockEnd: "0.67em",
    fontSize: "3rem",
    fontWeight: "bold",
  },
  h2: {
    //marginBlockStart: "0.83em",
    //marginBlockEnd: "0.83em",
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  h3: {
    //marginBlockStart: "1.00em",
    //marginBlockEnd: "1.00em",
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
  h3a: {
    //marginBlockStart: "1.00em",
    //marginBlockEnd: "1.00em",
    fontSize: "1.4rem",
    fontWeight: "normal",
  },
  h4: {
    //marginBlockStart: "1.33em",
    //marginBlockEnd: "1.33em",
    fontSize: "1.00rem",
    fontWeight: "bold",
    letterSpacing: "-.022rem"
  },
  h5: {
    //marginBlockStart: "1.67em",
    //marginBlockEnd: "1.67em",
    fontSize: "0.83rem",
    fontWeight: "bold",
  },
  h6: {
    //marginBlockStart: "2.33em",
    //marginBlockEnd: "2.33em",
    fontSize: "0.67rem",
    fontWeight: "bold",
  },

  caption: {
    fontWeight: 400,
    fontSize: "0.67rem",
    lineHeight: 1.66,
  },

  body1: {
    fontSize: "0.9rem",
    lineHeight: 1.57,
  },

  body2: {
    fontSize: "0.83rem",
    lineHeight: 1.66,
  },

  dialogtitle: {
    //marginBlockStart: "1.00em",
    //marginBlockEnd: "1.00em",
    fontSize: "1.17rem",
    fontWeight: "bold",
  },

  subtitle1: {
    fontSize: "0.83rem",
    fontWeight: 600,
    lineHeight: 1.57,
  },

  subtitle2: {
    fontSize: "0.83rem",
    fontWeight: 500,
    lineHeight: 1.66,
  },

  subtitle3: {
    fontSize: "0.67rem",
    fontWeight: 500,
    lineHeight: 1.66,
  },

  button: {
    textTransform: "none",
    lineHeight: 1.57,
  },
});

export default Typography;