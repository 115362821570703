import React, { useEffect, useState, useRef } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineOppositeContent, TimelineContent, TimelineDot } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import {
  Accordion, AccordionSummary, AccordionDetails, Badge, Box, Button, Card, CardContent, Container, Collapse, Grid, Icon, Paper, Stepper, Step, StepLabel, Typography, useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TypingEffect from '../components/page-items/TypingEffect';

import MainContainer from '../components/page-items/MainContainer';
import SlimMainContainer from '../components/page-items/SlimMainContainer';

import SpeedIcon from '@mui/icons-material/Speed';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ScaleIcon from '@mui/icons-material/Scale';
import VerifiedIcon from '@mui/icons-material/Verified';
import GavelIcon from '@mui/icons-material/Gavel';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import SplitScrollSection from '../components/page-items/SplitScrollSection';

const HoverButton = styled(Button)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out',
  alignSelf: 'center',
  border: `1px solid ${theme.palette.primary.blue}`,
  width: 'auto',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    border: `1px solid ${theme.palette.primary.blue}`,
  },
}));

const FullWidthBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.bg,
  padding: theme.spacing(2, 0),
}));

function HeroSection() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          width: '65%',
          height: '100%',
          backgroundColor: '#000',
          color: theme.palette.common.white,
          justifyContent: 'center',
          textAlign: 'start',
          px: 8,
        }}
      >
        <MainContainer>
          <Typography variant="h1" sx={{ color: "#fff", fontWeight: '300' }}>
            <strong>We enhance</strong> your operational capability so you can focus on
          </Typography>
          <Typography variant="h1" sx={{ color: "#fff", fontWeight: '300', mb: 2 }} gutterBottom>
            <strong>your impact.</strong>
          </Typography>
          <Typography variant="h3" sx={{ color: "#fff", fontWeight: '300', mb: 3 }} paragraph>
            Automate the complexities of data-intensive operational functions to boost your profits and ensure regulatory compliance <em>without the hassle</em>.
          </Typography>
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <HoverButton variant="contained" size="large" sx={{ backgroundColor: 'white', border: `1px solid ${theme.palette.primary.main}` }}>
              Discover How
            </HoverButton>
          </Link>
        </MainContainer>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '35%',
          height: '100%',
          backgroundImage: theme.palette.primary.gradientVertical,
          backgroundSize: 'cover',
          py: 10,
        }}
      >
      </Box>
    </Box>
  );
}

function MobileHeroSection() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundImage: theme.palette.primary.gradientVertical,
        backgroundSize: 'cover',
        color: theme.palette.common.white,
        py: 13,
        textAlign: 'center',
        '&::before': {
          content: '""', // Important for creating pseudo-elements
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.2)', // Black with 50% opacity
          zIndex: 1,
        },
        '& > *': { position: 'relative', zIndex: 2 }, // Ensures content is above the overlay
      }}
    >
      <MainContainer>
        <Typography variant="h1" sx={{ color: "#333", fontWeight: '300' }}>
          <strong>We enhance</strong> your operational capability
        </Typography>
        <Typography variant="h1" sx={{ color: "#333", fontWeight: '300', mb: 2 }} gutterBottom>
          so you can focus on <strong>your impact.</strong>
        </Typography>
        <Typography variant="h3" sx={{ color: "#333", fontWeight: '300', mb: 3 }} paragraph>
          Automate the complexities of data-intensive operational functions to boost your profits and ensure regulatory compliance <em>without the hassle</em>.
        </Typography>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <HoverButton variant="contained" size="large" sx={{ backgroundColor: 'white', border: `1px solid ${theme.palette.primary.main}` }}>
            Discover How
          </HoverButton>
        </Link>
      </MainContainer>
    </Box>
  );
}

function IsThisYouSection() {
  return (
    <MainContainer sx={{ mt: '90vh' }}>
      <Grid container spacing={4} sx={{ py: 2 }}>
        <Grid item xs={12} sm={5} md={5}>
          <Typography variant="h2" component="h2" sx={{ color: "#333", fontWeight: '300' }} gutterBottom>
            Does this sound like you?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <Grid container spacing={4} sx={{ gap: 0.5 }}>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Balancing Business Growth and Client Maintenance
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                You have built your business and focus on lengthy processes that you know well. But you also have a growing client maintenance job and need to keep generating new revenue.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Complex Transaction Administration
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                You handle complex transactions effectively, but the stress of managing the administration of those trades takes time away from more client-focused work.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Struggling with Manual Processes
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                Are your operations bogged down by outdated, manual workflows that slow you down and reduce efficiency?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Client Engagement Challenges
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                Do you find it difficult to provide personalized, timely interactions that keep clients happy and loyal?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Regulatory Compliance Concerns
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                Is keeping up with evolving regulatory requirements becoming a time-consuming and error-prone task?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Limited Data Insights
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                Are you lacking the tools to make informed, data-driven decisions quickly in response to market changes?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">
                Overwhelmed by Technology
              </Typography>
              <Typography variant="h4" component="p" sx={{ fontWeight: 400 }}>
                Does the thought of implementing new technology solutions feel daunting, even though you know your business needs it?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
}

function WhatWeDoSection() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MainContainer>
      <Typography variant="h3" component="h2" gutterBottom>
        What We Do
      </Typography>
      <Typography variant="body1" paragraph>
        We help traditional firms revolutionise their digital operations to keep pace with technological changes and stay ahead of their competitors. These firms have long been masters of their trade, creating successful businesses. However, reliance on manual processes leads to inefficiencies, hindering their growth potential. That's where we step in.
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        What sets us apart is our commitment to helping you deliver <strong>Operational Alpha</strong>.
      </Typography>
      <Typography variant="h5" component="h4" sx={{ mt: 2 }} gutterBottom>
        What is Operational Alpha?
      </Typography>
      <Typography variant="body1" paragraph>
        In short, Operational Alpha means viewing your operations as a source of revenue growth.
      </Typography>
      <Typography variant="body1" paragraph>
        Through enhanced operational efficiency, it is the value and competitive advantage gained through superior operational execution, such as innovative administration, effective client servicing, and strategic technological deployment.
      </Typography>
      <Typography variant="body1" paragraph>
        It enables firms to establish <strong>robust governance frameworks, optimise resources, and leverage cutting-edge technology</strong> for unparalleled portfolio management. This might include:
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>Automating workflows</li>
        <li>Refining risk management procedures</li>
        <li>Optimising reporting mechanisms</li>
      </Typography>
      <Box display="flex" flexDirection="column">
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography variant="h5" component="h4" sx={{ mt: 2 }} gutterBottom>
            But what is Alpha?
          </Typography>
          <Typography variant="body1" paragraph>
            Alpha is a measure of the active return on an investment, indicating the performance of that investment compared with a suitable market index.
          </Typography>
          <Typography variant="h5" component="h4" sx={{ mt: 2 }} gutterBottom>
            How does Operational Alpha extend growth?
          </Typography>
          <Typography variant="body1" paragraph>
            Operational Alpha is the value we bring through streamlining processes and implementing efficient procedures, independent of investment decisions. It empowers wealth managers to seize opportunistic investments, manage costs effectively, and elevate client servicing to new heights. In today's dynamic landscape, Operational Alpha is no longer a luxury—it's a necessity.
          </Typography>
          <Typography variant="body1" paragraph>
            The concept also extends to risk management and compliance, involving proactive measures to mitigate operational risks and ensure regulatory compliance through robust internal controls and enhanced cybersecurity measures. Achieving Operational Alpha ultimately contributes to overall investment performance by optimising operational processes and enhancing the firm's ability to generate traditional alpha through active investment management.
          </Typography>
          <Typography variant="body1" paragraph>
            We firmly believe that Operational Alpha will emerge as a cornerstone of success in the coming decade. With market returns projected to plateau and macro volatility on the rise, harnessing Operational Alpha will be paramount. At Enhanced Impact, we're here to guide you on this transformative journey, ensuring you not only survive but thrive in an ever-evolving market environment.
          </Typography>
        </Collapse>
        <Button onClick={handleExpandClick} size="small" sx={{ marginTop: 'auto', color: '#333', alignSelf: 'start' }}>
          {expanded ? 'Less' : 'Learn More'}
        </Button>
      </Box>
    </MainContainer>
  );
}

function OperationalAlphaSection() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MainContainer>
      <Card variant="outlined" sx={{ borderRadius: 4, marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h3" component="h2" gutterBottom>What is Operational Alpha?</Typography>
          <Typography variant="body1" paragraph>
            Operational Alpha involves enhancing operational efficiency, streamlining processes, and reducing costs within investment organizations. This might include automating workflows, refining risk management procedures, and optimizing reporting mechanisms. Moreover, Operational Alpha aims to generate additional value and competitive advantage through superior operational execution, such as innovative fund administration, effective client servicing, and strategic partnerships.
          </Typography>
          <Box display="flex" flexDirection="column">
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Typography variant="body1" paragraph>
                Alpha is a measure of the active return on an investment, the performance of that investment compared with a suitable market index.
              </Typography>
              <Typography variant="body1" paragraph>
                It's the value we bring through streamlining processes and implementing efficient procedures, independent of investment decisions. Operational alpha empowers wealth managers to seize opportunistic investments, manage costs effectively, and elevate client servicing to new heights. In today's dynamic landscape, Operational Alpha is no longer a luxury—it's a necessity. It enables wealth managers to establish robust investment governance frameworks, optimize resources, and leverage cutting-edge technology for unparalleled portfolio management.
              </Typography>
              <Typography variant="body1" paragraph>
                The concept extends to risk management and compliance, involving proactive measures to mitigate operational risks and ensure regulatory compliance through robust internal controls and enhanced cybersecurity measures. Ultimately, achieving Operational Alpha contributes to overall investment performance by optimizing operational processes and enhancing the firm's ability to generate traditional alpha through active investment management.
              </Typography>
              <Typography variant="body1" paragraph>
                We firmly believe that Operational Alpha will emerge as a cornerstone of success in the coming decade. With market returns projected to plateau and macro volatility on the rise, harnessing Operational Alpha will be paramount. At Enhanced Impact, we're here to guide you on this transformative journey, ensuring you not only survive but thrive in an ever-evolving market environment.
              </Typography>
            </Collapse>
            <Button onClick={handleExpandClick} size="small" sx={{ marginTop: 'auto', color: '#333', alignSelf: 'start' }}>
              {expanded ? 'Less' : 'Learn More'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </MainContainer>
  );
}

const steps = [
  { title: "Automation and Operational Efficiency Audit", content: "We begin with an in-depth audit of your current operations to identify bottlenecks or inefficiencies and areas well-suited for automation or future enhancements. This includes examining trade data management, performance metrics, assessing your current operational workflows, and more." },
  { title: "Deliver RAG Rated Report", content: "Following the audit, we provide a RAG-rated report, outlining areas for improvement and opportunities for enhancement, helping you understand the critical priorities." },
  { title: "Fix or Remediate Issues Raised", content: "We address the key issues identified in the report, implementing fixes and remediations to enhance operational efficiency and performance. Custom Solutions: Leveraging tools like React and Material-UI, we create bespoke automation solutions tailored to your specific needs. Integration: Our solutions seamlessly integrate with your existing systems, ensuring minimal disruption and maximum enhancement." },
  { title: "Ongoing Support", content: "Our commitment extends beyond initial fixes. We offer ongoing support and advisory services to ensure continuous operational excellence and adaptation to new challenges." }
];

function HowItWorks() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <Typography variant="h3" component="h2" gutterBottom>
        How we work
      </Typography>
      <Box>
        <Timeline position={isMobile ? 'right' : 'alternate'}>
          {steps.map((step, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent sx={{ display: isMobile ? 'none' : 'inherit' }}>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    border: `2px solid ${theme.palette.primary.main}`,
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: theme.palette.primary.bg,
                  }}
                >
                  <Typography variant="h5" sx={{ color: "#333" }}>
                    {index + 1}
                  </Typography>
                </TimelineDot>
                {index < steps.length - 1 && <TimelineConnector sx={{ bgcolor: 'primary.main' }} />}
              </TimelineSeparator>
              <TimelineContent sx={{
                mb: 3,
              }}>
                <Typography variant="h5">
                  {step.title}
                </Typography>
                <Typography variant="body1">
                  {step.content}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </MainContainer>
  );
}

function OurApproachStepper() {
  const steps = [
    "Automation and Operational Efficiency Audit",
    "Deliver RAG Rated Report",
    "Fix or Remediate Issues Raised",
    "Ongoing Support"
  ];

  const stepRefs = useRef(steps.map(() => React.createRef()));
  const [visibleSteps, setVisibleSteps] = useState(new Array(steps.length).fill(false));

  const stepContent = [
    "We begin with an in-depth audit of your current operations to identify bottlenecks or inefficiencies and areas well-suited for automation or future enhancements. This includes examining trade data management, performance metrics, assessing your current operational workflows, and more.",
    "Following the audit, we provide a RAG-rated report, outlining areas for improvement and opportunities for enhancement, helping you understand the critical priorities.",
    "We address the key issues identified in the report, implementing fixes and remediations to enhance operational efficiency and performance. Custom Solutions: Leveraging tools like React and Material-UI, we create bespoke automation solutions tailored to your specific needs. Integration: Our solutions seamlessly integrate with your existing systems, ensuring minimal disruption and maximum enhancement.",
    "Our commitment extends beyond initial fixes. We offer ongoing support and advisory services to ensure continuous operational excellence and adaptation to new challenges."
  ];

  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;
      const newVisibleSteps = visibleSteps.slice();

      stepRefs.current.forEach((ref, index) => {
        const top = ref.current.getBoundingClientRect().top;
        if (top <= viewportHeight * 0.9) {
          newVisibleSteps[index] = true;
        }
      });

      setVisibleSteps(newVisibleSteps);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleSteps]);

  return (
    <MainContainer>
      <Typography variant="h3" component="h2" gutterBottom>
        How we work
      </Typography>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        {steps.map((step, index) => (
          <Box key={index} ref={stepRefs.current[index]} sx={{
            transition: 'transform 0.8s, opacity 0.8s',
            transform: `translateY(${visibleSteps[index] ? 0 : 50}px)`,
            opacity: visibleSteps[index] ? 1 : 0
          }}>
            <Typography variant="h4" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box component="span" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "#fff",
                height: '32px',
                width: '32px',
                borderRadius: '50%',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1rem',
                color: '#000'
              }}>
                {index + 1}
              </Box>
              {step}
            </Typography>
            <Typography variant="body1" paragraph>
              {stepContent[index]}
            </Typography>
          </Box>
        ))}
      </Box>
    </MainContainer>
  );
}

function BenefitsSection() {
  const theme = useTheme();
  return (
    <MainContainer>
      <Typography variant="h3" component="h2" sx={{ mb: 4 }} gutterBottom>
        Benefits of Automation and Seeking Operational Alpha
      </Typography>
      <Grid container spacing={2}>
        {[
          { heading: "Say goodbye to manual errors", text: "Enhance accuracy and reduce errors with automated systems.", icon: <VerifiedIcon /> },
          { heading: "Simplify complex processes", text: "Streamline operations and reduce time spent on routine tasks.", icon: <SpeedIcon /> },
          { heading: "Deliver operational performance", text: "Implement scalable solutions that grow with your business.", icon: <ScaleIcon /> },
          { heading: "Drive cost efficiency", text: "Lower operational costs lead to improved profitability.", icon: <TrendingDownIcon /> },
          { heading: "Ensure regulatory compliance", text: "Stay ahead of compliance with smart, adaptive processes.", icon: <GavelIcon /> },
          { heading: "Enhance customer satisfaction", text: "Improve service quality and responsiveness, leading to higher customer loyalty and retention.", icon: <SentimentVerySatisfiedIcon /> }
        ].map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={4} sx={{
              height: '100%', // ensures each Paper takes up 100% of the grid item's height
              display: 'flex', flexDirection: 'column',
              alignItems: 'start', justifyContent: 'start', // distributes space evenly vertically
              padding: 2, borderRadius: 4, gap: 2,
              borderLeft: `4px solid ${theme.palette.primary.main}`
            }}>
              <Typography variant="h4">{benefit.heading}</Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
                <Icon sx={{ width: 'auto', height: 'auto', overflow: 'hidden' }}>{benefit.icon}</Icon>
                <Typography variant="body1">{benefit.text}</Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </MainContainer>
  );
}

function CallToActionSection() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: theme.palette.primary.gradientVertical,
        backgroundSize: 'cover',
        py: 10,
        textAlign: 'center',
        color: 'white'
      }}
    >
      <MainContainer>
        <Typography variant="h1" sx={{ color: "#333", fontWeight: '300', mb: 2 }} gutterBottom>
          Get in touch.
        </Typography>
        <Typography variant="h3" sx={{ color: "#333", fontWeight: '300', mb: 3 }} paragraph>
          Want to keep your organisation moving forward, faster?
        </Typography>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <HoverButton variant="contained" size="large" sx={{ backgroundColor: 'white', border: `1px solid ${theme.palette.primary.main}` }}>
            Start now
          </HoverButton>
        </Link>
      </MainContainer>
    </Box>
  );
}

function OurServices() {
  const theme = useTheme();
  const steps = [
    {
      title: "Strategic Operations Audit",
      description: "Our engagement begins with a comprehensive evaluation of your existing operational framework, pinpointing critical areas where automation and governance enhancements can drive efficiency. This strategic audit delves into your trade data management, performance analytics, and existing operational practices to uncover latent potential for streamlined operations and increased profitability."
    },
    {
      title: "Tailored Project Solutions",
      description: "Post-audit, we transition to implementing bespoke solutions tailored to your specific needs. Whether it's resolving identified inefficiencies or deploying new technology integrations, our ongoing support ensures that your operations not only meet current standards but are also poised to adapt to future market dynamics and regulatory requirements. This continuous partnership aims to fortify your business resilience and enhance your operational excellence."
    },
    {
      title: "Direct Project Engagement",
      description: "For clients with specific operational challenges or technology needs, we offer direct project engagements. Whether you have a clear target for automation, a need for technology integration, or a performance bottleneck, our team is ready to jump in and deliver targeted solutions without the prerequisite of a full audit. This service is ideal for addressing immediate issues swiftly and effectively, ensuring minimal disruption to your ongoing operations."
    }
  ];

  return (
    <SlimMainContainer sx={{ py: 4, px: 2 }}> {/* Remove horizontal padding */}
      <Typography variant="h3" component="h2" sx={{ px: 2 }}>
        Our Services
      </Typography>
      <Grid container spacing={0} sx={{ mx: 0, width: '100%' }}> {/* Ensure no margin and full width */}
        {steps.map((step, index) => (
          <Grid item xs={12} sm={12} md={12} key={index} sx={{ mt: 2, px: 0 }}> {/* Remove horizontal padding */}
            <Card sx={{
              backgroundColor: theme.palette.primary.bg,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'start',
              px: 2,
            }}>
              <CardContent sx={{ px: 0, py: 2 }}>
                <Typography variant="h4" component="div" gutterBottom>
                  {step.title}
                </Typography>
                <Typography variant="body1">
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </SlimMainContainer>
  );
}

function SplitSection() {
  const theme = useTheme();

  const subsections = [
    { subtitle: "Strategic Operations Audit", body: "Our engagement begins with a comprehensive evaluation of your existing operational framework, pinpointing critical areas where automation and governance enhancements can drive efficiency. This strategic audit delves into your trade data management, performance analytics, and existing operational practices to uncover latent potential for streamlined operations and increased profitability." },
    { subtitle: "Tailored Project Solutions", body: "Post-audit, we transition to implementing bespoke solutions tailored to your specific needs. Whether it's resolving identified inefficiencies or deploying new technology integrations, our ongoing support ensures that your operations not only meet current standards but are also poised to adapt to future market dynamics and regulatory requirements. This continuous partnership aims to fortify your business resilience and enhance your operational excellence." },
    { subtitle: "Direct Project Engagement", body: "For clients with specific operational challenges or technology needs, we offer direct project engagements. Whether you have a clear target for automation, a need for technology integration, or a performance bottleneck, our team is ready to jump in and deliver targeted solutions without the prerequisite of a full audit. This service is ideal for addressing immediate issues swiftly and effectively, ensuring minimal disruption to your ongoing operations." },
  ];

  return (
    <div>
      <SplitScrollSection title="Our Services" subsections={subsections} />
    </div>
  );
}

function Home() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      {isLargeScreen ? <HeroSection /> : <MobileHeroSection />}
      <FullWidthBox>
        <IsThisYouSection />
      </FullWidthBox>
      <WhatWeDoSection />
      <FullWidthBox>
        <BenefitsSection />
      </FullWidthBox>
      {isLargeScreen ? <SplitSection /> : <OurServices />}
      <FullWidthBox>
        <HowItWorks />
      </FullWidthBox>
      <CallToActionSection />
    </div>
  );
}

export default Home;
